import React, { Component } from 'react';
import { Switch } from "react-router";
import { Route } from "react-router-dom";

import Home from "./Home";
import NotFound from "../NotFound";
import ContentHeader from "../ContentHeader";

class App extends Component {

    render() {
        return (
            <div className="App table-container">
                <div className="table-block footer-push jumble-content">
                    <ContentHeader/>
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default App;