import 'react-app-polyfill/ie11';
import 'core-js/fn/symbol/iterator.js';
import 'core-js/es6/array';
import 'core-js/es7/array';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";

import JumbleApp from "./components/App";
import JumbleApi from "./components/api/App";

import "./css/normalize.css"
import "./css/skeleton.css"
import "./css/Jumble.css"
import "./css/Resultlist.css"
import "./css/Searchbox.css"
import "./css/Filter.css"
import "./css/Autocomplete.css"

ReactDOM.render(
    <BrowserRouter>
        { window.location.hostname.indexOf("api.jumble") !== -1 ? <JumbleApi /> : <JumbleApp /> }
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
