import React, {Component} from 'react';
import {Link} from "react-router-dom";
import LocationAutocomplete from "./LocationAutocomplete";
import ExpandCollapse from "./ExpandCollapse";
import {isJobs, isMobileResolution} from "../utils/utils";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchParams: {
                q: "",
                l: ""
            },
            searchClosed: true,
            isOpen: !isMobileResolution()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleRadiusChange = this.handleRadiusChange.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
//        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        if (this.state.isOpen !== !isMobileResolution()) {
            this.setState({isOpen: !this.state.isOpen});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const query = this.props.searchParams.q;
        const location = this.props.searchParams.l;
        if (query !== prevProps.searchParams.q || location !== prevProps.searchParams.l) {
            this.updateSearchParams({ q: query, l: location });
        }
    }

    handleFormSubmit(event) {
        if (isMobileResolution()) {
            this.onToggleSearchInput();
        }
        event.preventDefault();
        const { q, l } = event.target.elements;
        const queryParams = { q: q.value, l: l.value };
        this.props.onSearch(queryParams);
    }

    handleRadiusChange(event) {
        const searchParams = this.updateSearchParams({radius: event.target.options[event.target.selectedIndex].value});
        this.props.onSearch(searchParams);
    }

    handleQueryChange(event) {
        this.updateSearchParams({q: event.target.value});
    }

    updateSearchParams(params) {
        const searchParams = Object.assign({}, this.state.searchParams, params);
        this.setState({ searchParams });
        return searchParams;
    }

    handleLocationSelected(suggestion) {
        const searchParams = this.updateSearchParams({l: suggestion.name});
        this.props.onSearch(searchParams);
    }

    onToggleSearchInput() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        return (
            <header className="app-header">
                <nav className="navbar navbar-expand-lg navbar-dark">

                    <div className="mobile-navigation-bar">
                        <div className="mobile-navigation-bar-content">
                            <button className="menu-button-mobile" onClick={this.props.onToggleFilterInput}/>
                            <Link className="jumble-logo mobile" to="/">
                                Jumble  <span className="jumble-logo-section">{isJobs()
                                    ? "Jobs"
                                    : "Immo"
                                }</span>
                                <div className={"home-icon home-icon-immo-white"}/>
                            </Link>
                            <button className="search-button-mobile" onClick={this.onToggleSearchInput.bind(this)}/>
                        </div>
                    </div>
                    <ExpandCollapse isOpen={this.state.isOpen}>
                        <div className="container">
                            <div className="searchbox">
                                <form method="get" onSubmit={this.handleFormSubmit}>
                                    <Link className="jumble-logo desktop" to='/'>Jumble <span className="jumble-logo-section">{isJobs()
                                        ? "Jobs"
                                        : <span>Immo<span className="beta-badge">Beta</span></span>
                                    }</span></Link>
                                    <div className="row valign-bottom">
                                        <div className="six columns">
                                            <input className="u-full-width search-input"
                                                   type="text"
                                                   name="q"
                                                   placeholder="Stichwort oder Jobtitel"
                                                   id="query"
                                                   value={this.state.searchParams.q}
                                                   autoComplete="off"
                                                   onChange={this.handleQueryChange}/>
                                        </div>
                                        <div className="five columns">
                                            <div className="location-field">
                                                <LocationAutocomplete
                                                    location={this.state.searchParams.l}
                                                    onLocationSelected={this.handleLocationSelected.bind(this)}/>
                                            </div>
                                            <div className="radius-field">
                                                <select name="radius" id="radius" onChange={this.handleRadiusChange} defaultValue="30">
                                                    <option value="0">dieser Ort</option>
                                                    <option value="10">10 km</option>
                                                    <option value="30">30 km</option>
                                                    <option value="50">50 km</option>
                                                    <option value="70">70 km</option>
                                                    <option value="100">100 km</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="one columns">
                                            <button className="search-button u-full-width" type="submit"/>
                                        </div>
                                        <div className="one columns menu-button-desktop">
                                            <button className="menu-button u-full-width" type="button" onClick={this.props.onToggleFilterInput}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ExpandCollapse>
                </nav>
            </header>
        );
    }
}

export default Header;
