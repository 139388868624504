import React, { Component } from 'react';
import {toQueryString} from "../utils/utils";

class FilterList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            activeTags: []
        };
        this.reset = this.reset.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.addFilter = this.addFilter.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.searchParams.q !== prevProps.searchParams.q ||
            this.props.searchParams.l !== prevProps.searchParams.l ||
            this.props.searchParams.lat !== prevProps.searchParams.lat ||
            this.props.searchParams.lng !== prevProps.searchParams.lng ||
            this.props.searchParams.radius !== prevProps.searchParams.radius ||
            this.props.searchParams.tags !== prevProps.searchParams.tags
        ) {
            this.updateFilterList();
        }
    }

    updateFilterList() {
        const activeTags = this.props.searchParams.tags ? this.props.searchParams.tags.split(",") : [];
        fetch(this.props.url + "?" + toQueryString(this.props.searchParams))
            .then(results => {
                if (!results.ok) {
                    throw new Error(results.statusText);
                }
                return results.json();
            })
            .then(tags => {
                this.setState({ tags, activeTags })
            })
            .catch(e => console.error(e));
    }

    render() {
        return (
            <div className="filterfields">
                <div className="container">
                    {this.state.activeTags && this.state.activeTags.length > 0 && (
                        <div className="filter">
                            <label htmlFor="tags"><span style={{cursor: "pointer"}} onClick={this.reset}>Angewandte Tags</span></label>
                            <div id="tags">
                                <ul className="tag-list">
                                    {this.state.activeTags
                                        .filter((value, index, self) => self.indexOf(value) === index)
                                        .map(tagName => { return (
                                            <li key={"active-filter-" + tagName}
                                                className="taggle applied"
                                                onClick={() => this.removeFilter(tagName)}>{tagName}</li>
                                        )})
                                    }
                                </ul>
                            </div>
                        </div>
                    )}
                    <div style={{clear: "both"}}/>
                    {this.state.tags && this.state.tags.length > 0 && (
                        <div className="filter">
                            <label htmlFor="most-tagged">Meist vertaggt</label>
                            <div id="most-tagged">
                                <ul className="tag-list">
                                    {this.state.tags
                                        .filter(tag => this.state.activeTags.includes(tag.name) === false)
                                        .map(tag => { return (
                                            <li key={tag.name}
                                                className="taggle"
                                                onClick={() => this.addFilter(tag.name)}>{tag.name}</li>
                                        )})
                                    }
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    removeFilter(tagName) {
        const activeTags = this.state.activeTags.filter(t => t !== tagName);
        this.setState({ activeTags });
        this.props.onRemoveFilter(tagName);
    }

    addFilter(tagName) {
        let activeTags = this.state.activeTags;
        activeTags.push(tagName);
        this.setState({ activeTags });
        this.props.onFilter(tagName);
    }

    reset() {
        // "taggle.removeAll();initialize();"
    }
}

export default FilterList;
