import React, {Component} from 'react';
import LocationAutocomplete from "./LocationAutocomplete";
import {setPageMeta, toQueryString} from "../utils/utils";
import * as LocalStorageUtils from "../utils/LocalStorageUtils";
import {TYPE_CHANGE_EVENT} from "../config";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: "",
            lat: null,
            lng: null,
            type: "jobs"
        };
    }

    componentDidMount() {
        const data = LocalStorageUtils.getValue();
        let type = "jobs";
        if (data) {
            type = data.type || type;
            this.setState({
                location: data.location,
                type: "jobs"
            });
        }
        setPageMeta({
            title: type === "jobs"
            ? "Jobsuchmaschine Jumble - Jobs und Stellenangebote"
            : "Immobilien-Suchmaschine Jumble - Wohnungen und Häuser zum Mieten und Kaufen",
            description: type === "jobs"
            ? "Jobsuche in Deutschland mit Jumble, der Suchmaschine für Stellenangebote. Finden Sie aktuelle Jobs und Stellenangebote in Deutschland und bewerben Sie sich."
            : "Immobiliensuche in Deutschland mit Jumble, der Suchmaschine für Wohnungen und Häuser. Finden Sie aktuelle Immobilien zum Mieten und Kaufen in Deutschland und sichern Sie sich Ihr neues Zuhause.",
            keywords: type === "jobs"
            ? "Jumble, Jobsuche, Jobs, Suchmaschine, Stellenangebote, Stellenangebot, Job, Jobbörse, Stellenanzeigen, Jobs suchen, Karriere, Einstellung, Arbeit, Jobs finden, Stellenanzeige, Stellenmarkt, Anstellung, Arbeit, Deutschland, jumble.de"
            : "Jumble, Immobiliensuche, Immobilien, Haus, Häuser, Wohnung, Wohnungen, Wohnungssuche, Kaufen, Mieten, Haus kaufen, Haus mieten, Wohnung kaufen, Zuhause, Wohnung mieten, Wohnungsangebot, Häusermarkt, Wohnungsmarkt, Immobilienanzeige, Deutschland, jumble.de"
        });
    }

    render() {
        return (
            <div className="vertical-center">
                <div className="container">
                    <div className="search-box-home">
                        <div className="main-logo-wrapper">
                            <div className="main-logo">
                                <img className="u-max-full-width" src="../img/jumble.svg"
                                     alt={this.state.type === "jobs"
                                         ? "Jumble - die Jobsuchmaschine"
                                         : "Jumble - die Immobilienuchmaschine"} width="450"/>
                            </div>
                        </div>
                        <div className="home-search-box">
                        {/*
                            <div className="home-type-toggle">
                                <div onClick={() => this.handleTypeChange("jobs")} className={this.isJobSearch() ? "toggle-home selected" : "toggle-home"} value="jobs" selected={this.state.type === "jobs"}>
                                    <div className={this.state.type === "jobs"
                                        ? "section-icon home-icon-jobs"
                                        : "section-icon home-icon-jobs-inactive"} />&nbsp;<span className="toggle-home-text">Jobs</span>
                                </div>
                                <div onClick={() => this.handleTypeChange("realestate")} className={!this.isJobSearch() ? "toggle-home selected" : "toggle-home"} value="realestate" selected={this.state.type === "realestate"}>
                                    <div className={
                                        this.state.type === "realestate"
                                            ? "section-icon home-icon-immo"
                                            : "section-icon home-icon-immo-inactive"}/>
                                            &nbsp;<span className="toggle-home-text"><span className="toggle-home-text-placeholder"/></span>
                                </div>
                            </div>
                            <form method="get" action={"/" + this.state.type} onSubmit={this.formSubmit.bind(this)} style={{marginBottom: 0}}>
                                <div className="row">
                                    <div className="six columns">
                                        <input className="u-full-width search-input"
                                               type="text"
                                               name="q"
                                               placeholder={this.isJobSearch() ? "Stichwort, Jobtitel oder Kategorie" : "Eigenschaft der Immobilie"}
                                               id="query"
                                               autoComplete="off"/>
                                    </div>
                                    <div className="five columns">
                                        <LocationAutocomplete location={this.state.location}
                                                          onLocationSelected={this.handleLocationSelected}/>
                                    </div>
                                    <div className="one columns">
                                        <button id="submit" className="search-button search-button-home u-full-width" type="submit"/>
                                    </div>
                                </div>
                            </form>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isJobSearch() {
        return true; // this.state.type === "jobs";
    }

    handleLocationSelected = (suggestion) => {
        const coordinates = suggestion.location.split(" ");
        this.setState({
            location: suggestion.name,
            lat: coordinates[1],
            lng: coordinates[9]
        }, () => this.persistConfig());
    };

    persistConfig = () => {
        const data = {
            location: this.state.location,
            lat: this.state.lat,
            lng: this.state.lng,
            type: this.state.type
        };
        LocalStorageUtils.setValue(data);
    };

    handleTypeChange = (type) => {
        this.setState({type}, () => this.persistConfig());
        window.dispatchEvent(new CustomEvent(TYPE_CHANGE_EVENT, { detail: type }));
    };

    formSubmit(event) {
        event.preventDefault();
        const { q, l } = event.target.elements;
        const queryParams = {
          q: q.value,
          l: l.value
        };
        const action = event.target.action;
        const path = action.substring(action.lastIndexOf('/'));
        this.props.history.push(path + '?' + toQueryString(queryParams));
    }
}

export default Home;
