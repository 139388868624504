import React, {Component} from 'react';

const DEFAULT_HEIGHT = 700;
const DEFAULT_DURATION = 0.7;

class ExpandCollapse extends Component {

    contentDiv = null;
    contentHeight = null;
    duration = null;

    constructor(props) {
        super(props);
        this.state = {
            overflow: props.isOpen ? "visible" : "hidden"
        };
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.contentHeight = null;
            this.duration = null;
        });
    }

    animationEnded() {
        if (this.props.isOpen && this.contentHeight === null) {
            this.contentHeight = this.contentDiv.clientHeight;
            this.duration = 0.4 + 0.0005 * this.contentDiv.clientHeight;
        }
        this.setState({ overflow: this.props.isOpen ? "visible" : "hidden" });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.isOpen !== nextProps.isOpen) {
            this.setState({ overflow: "hidden" });
        }
    }

    render() {
        return (
            <div ref={div => this.contentDiv = div}
                 onTransitionEnd={this.animationEnded.bind(this)}
                 style={{
                     transition: `max-height ${this.duration || DEFAULT_DURATION}s ease-in-out`,
                     overflow: `${this.state.overflow}`,
                     maxHeight: `${this.props.isOpen ? (this.contentHeight || DEFAULT_HEIGHT) + "px" : 0}`
                 }}>
                {this.props.children}
            </div>
        )
    }
}

export default ExpandCollapse;