import React, { Component } from 'react';
import {dateStringToDate, isMobileResolution, timeBetween} from "../utils/utils";

class RealestateResultItem extends Component {

    filterByTag(event, tag) {
        if (!isMobileResolution()) {
            event.preventDefault();
            this.props.onFilter(tag);
        }
    }

    render() {
        return (
            <a href={this.props.url} title={this.props.title} rel="nofollow">
                <div className="row result-item">
                    <div className="result-item-wrapper u-full-width">
                        <div className="result-item-details">
                            <div className="row">
                                <div className="twelve columns">
                                    <div className="result-item-title">{this.props.title}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="result-item-advertiser realestate-main-info-column">
                                    <div className="result-item-advertiser text">{this.props.source}</div>
                                    <span>&nbsp;-&nbsp;</span>
                                    {this.props.locations
                                        .filter ((value, index, array) => array.indexOf(value) === index)
                                        .map(location => {
                                            return (
                                                <div key={location} className="result-item-tag location">{location}</div>
                                            )
                                    })}
                                </div>
                                <div className="realestate-info">{ this.props.price && (
                                    <span className="realestate-info__value">{this.props.price.toString().replace(".", ",")}
                                    <span className="realestate-info__suffix">€</span></span>)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="result-item-description realestate-main-info-column">{this.props.description}</div>
                                <div className="realestate-info">{ this.props.size && (
                                    <span className="realestate-info__value">{this.props.size.toString().replace(".", ",")}
                                        <span className="realestate-info__suffix">m&sup2;</span>
                                    </span>)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="result-item-additional-information realestate-main-info-column">
                                    <div className="result-item-startdate">{timeBetween(new Date(), dateStringToDate(this.props.startDate))}</div>
                                    <div className="result-item-tags">
                                        { this.props.tags && this.props.tags.map(tag => {
                                            return (
                                                <div key={tag} className="result-item-tag" onClick={(event) => this.filterByTag(event, tag)}>{tag}</div>
                                            )})
                                        }
                                    </div>
                                </div>
                                <div className="realestate-info">{ this.props.rooms && (
                                    <span className="realestate-info__value">{this.props.rooms}
                                    <span className="realestate-info__suffix">Zi.</span></span>
                                )}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}

export default RealestateResultItem;
