import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    API_BASE_CUNUM,
    JOBS_PATH,
    REALESTATE_PATH,
    STATISTICS_CITY_PATH,
    STATISTICS_PATH,
    STATISTICS_PROFESSION_PATH,
} from "../config";
import ContentHeader from "./ContentHeader";
import {setPageMeta} from "../utils/utils";

class Discover extends Component {

    CHARACTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y', 'z'];

    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            professions: [],
            realestateProps: [],
            character: 'a'
        }
    }

    componentDidMount() {
        const path = window.location.pathname.split('/');
        let character = path[2];
        if (!character || character === "") {
            character = 'a';
        }
        setPageMeta({
            title: 'Entdecke Jobs und Immobilien nach Städten und Berufsgruppen mit ' + character.toUpperCase() + ' - Jumble',
            description: `Entdecke Jobs und Immobilien auf Jumble. Finde Stellenangebote, Häuser und Wohnungen nach Städten oder Berufsgruppen mit ${character.toUpperCase()}.`,
            keywords: "Entdecke Jobs, Jobs nach Städten, Jobs nach Berufsgruppen, Jumble"
        });
        this.updateStatistics(character);
    }

    render() {
        return (
            <div>
                <ContentHeader/>
                <div className="container">
                    <ul className="alphabetic-list">
                        {this.CHARACTERS.map(character => {
                            return <li key={character} className="discover-link">
                                    <Link onClick={() => this.updateStatistics(character)}
                                          to={character === 'a' ? '/discover/' : '/discover/' + character}
                                          title={"Entdecke Jobs und Immobilien mit " + character.toUpperCase()}
                                          className={this.state.character === character ? "alphabetic-list-item-selected" : undefined}>
                                        {character.toUpperCase()}
                                    </Link>
                                   </li>
                        })}
                    </ul>

                    {this.state.cities && this.state.cities.length > 0 && (
                        <div>
                            <h1 className="tag-list-title">Jobs nach Städten</h1>
                            <div className="tag-cloud">
                                <ul className="tag-list">
                                    {this.state.cities.map((city, index) => {
                                        return <li key={`${city.name}-${index}`}><Link to={"/jobs?l=" + city.name} title={"Jobs in " + city.name}>{city.name}</Link></li>
                                    })}
                                </ul>
                                <div className="clear-float"/>
                            </div>
                        </div>
                    )}

                    {this.state.professions && this.state.professions.length > 0 && (
                        <div>
                            <h1 className="tag-list-title">Jobs nach Berufsgruppen</h1>
                            <div className="tag-cloud">
                                <ul className="tag-list">
                                    {this.state.professions.map((profession, index) => {
                                        return <li key={`${profession}-${index}`}>
                                            <Link to={"/jobs?q=" + profession.name} title={profession.name + " Jobs"}>{profession.name}</Link>
                                        </li>
                                    })}
                                </ul>
                                <div className="clear-float"/>
                            </div>
                        </div>
                    )}

                    {/*
                    {this.state.realestateProps && this.state.realestateProps.length > 0 && (
                        <div>
                            <h1 className="tag-list-title">Immobilien nach Eigenschaften</h1>
                            <div className="tag-cloud">
                                <ul className="tag-list">
                                    {this.state.realestateProps.map((property, index) => {
                                        return <li key={`${property}-${index}`}>
                                            <Link to={"/realestate?tags=" + property.name} title={"Immobilien mit " + property.name}>{property.name}</Link>
                                        </li>
                                    })}
                                </ul>
                                <div className="clear-float"/>
                            </div>
                        </div>
                    )}

                    {this.state.cities && this.state.cities.length > 0 && (
                        <div>
                            <h1 className="tag-list-title">Immobilien nach Städten</h1>
                            <div className="tag-cloud">
                                <ul className="tag-list">
                                    {this.state.cities.map((city, index) => {
                                        return <li key={`${city.name}-${index}`}><Link to={"/realestate?l=" + city.name} title={"Immobilien in " + city.name}>{city.name}</Link></li>
                                    })}
                                </ul>
                                <div className="clear-float"/>
                            </div>
                        </div>
                    )}
                    */}
                </div>
                <br/>
            </div>
        );
    }

    updateStatistics(character) {
        fetch(API_BASE_CUNUM + JOBS_PATH + STATISTICS_CITY_PATH + character)
            .then(results => {
                if (!results.ok) {
                    throw new Error(results.statusText);
                }
                return results.json();
            })
            .then(cities => {
                this.setState({ cities, character })
            })
            .catch(e => console.error(e));

        fetch(API_BASE_CUNUM + JOBS_PATH + STATISTICS_PROFESSION_PATH + character)
            .then(results => {
                if (!results.ok) {
                    throw new Error(results.statusText);
                }
                return results.json();
            })
            .then(professions => {
                this.setState({ professions })
            })
            .catch(e => console.error(e));

        fetch(API_BASE_CUNUM + REALESTATE_PATH + STATISTICS_PATH + "?count=100")
            .then(results => {
                if (!results.ok) {
                    throw new Error(results.statusText);
                }
                return results.json();
            })
            .then(realestateProps => {
                const properties = realestateProps.filter(property => property.name.charAt(0).toLowerCase() === character);
                this.setState({ realestateProps: properties })
            })
            .catch(e => console.error(e));
    }
}

export default Discover;
