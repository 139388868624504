import React from 'react';
import {Link} from "react-router-dom";

const ContentHeader = () => {

    return (
        <header className="app-header">
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="searchbox-content">
                    <div className="container">
                        <div className="searchfields" id="searchfields">
                            <div className="row valign-bottom">
                                <div className="two columns">
                                    <Link className="nav-link" to='/'>
                                        <img src="../img/jumble_white.svg"
                                             alt="Jumble - die Jobsuchmaschine"
                                             width="125"
                                             height="50"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default ContentHeader;
