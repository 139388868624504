import React, {Component} from 'react';
import {Route} from "react-router-dom";
import {Switch} from "react-router";

import Footer from "./Footer";
import NotFound from "./NotFound";
import Impressum from "./Impressum";
import About from "./About";
import Discover from "./Discover";
import Privacy from "./Privacy";
import JobsResultList from "./JobsResultList";
import RealestateResultList from "./RealestateResultList";
import Home from "./Home";

class App extends Component {

    render() {
        return (
            <div className="App table-container">
                <div className="table-block footer-push jumble-content">
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/about' component={About}/>
                        <Route exact path='/impressum' component={Impressum}/>
                        <Route exact path='/privacy' component={Privacy}/>
                        <Route exact path='/discover*' component={Discover}/>
                        <Route exact path='/jobs' component={JobsResultList}/>
                        <Route exact path='/realestate' component={RealestateResultList}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
                {/*<Footer/>*/}
            </div>
        );
    }
}

export default App;