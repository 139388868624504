import React, {Component} from 'react';
import ContentHeader from "./ContentHeader";
import {setPageMeta} from "../utils/utils";

class About extends Component {

    componentDidMount() {
        setPageMeta({
            title: "Über Jumble - Jumble",
            description: "Über Jumble",
            keywords: "Über Jumble, Was ist Jumble, Jumble"
        });
    }

    render() {
        return (
            <div>
                <ContentHeader/>
                <div className="container">
                    <div className="jumbotron main-teaser">
                        <div className="no-padding-top">
                            <h1 className="display-3">Über Jumble</h1>
                        </div>
                    </div>

                    <div className="half-padding-top">
                        <div className="section">
                            <p>
                                Jumble ist eine neuartige Suchmaschine für Stellenangebote. Jumble ist kostenfrei zu nutzen und bis auf weiteres als gemeinnütziges Projekt ausgelegt.
                                Seid also unbesorgt, wir belästigen Euch nicht mit Werbung oder sonstigen Bezahlformen, sondern
                                konzentrieren uns darauf die bestmögliche Nutzererfahrung bieten zu können.
                            </p>
                        </div>
                        <h5>Wie funktioniert Jumble?</h5>
                        <div className="section">
                            <p>
                                Unsere Stellensuchmaschine wühlt sich durch das World Wide Web, um ein bis dato nicht
                                für möglich gehaltenes Angebot an Stellenangeboten im deutschsprachigen Raum bieten zu können.
                                Haben wir die Stellenangebote erst einmal gefunden und in unseren Bestand aufgenommen, gehen die
                                Jobs durch einen mehrphasigen Klassifizierungsprozess, so dass wir unseren Nutzern diverse Such-
                                und Filtermöglichkeiten auf unser Job-Portfolio bieten können. Die Klassifizierung der Jobs
                                erfolgt vollautomatisch mithilfe eines eigens dafür implementierten Text Mining Algorithmus.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
