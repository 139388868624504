import React from 'react';
import {PAGE_SIZE} from "../config";

const Pagination = ({hits, currentPage, totalPageCount, onPagination}) => {

    const paginate = (event, start) => {
        event.preventDefault();
        onPagination(start);
    };

    return hits > 0 ? (
        <div id="pagination" className="pagination-new">
            <div className="row">
                <div className="pagination-section columns left">
                    {currentPage !== 0
                        ? <a className="paginate-direction"
                             title={`Zurück zu Seite ${currentPage}`}
                             href="/jobs"
                             onClick={event => paginate(event, (currentPage - 1) * PAGE_SIZE)}>
                            <img src="../img/backward.svg" alt="backward"/> <span
                            className="paginate-direction-text">zurück</span>
                        </a>
                        : <span>&nbsp;</span>
                    }
                </div>
                <div className="pagination-section columns middle">
                    Seite <span className="bold">{currentPage + 1}</span> von <span
                    className="bold">{Math.max(totalPageCount, 1)}</span>
                </div>
                <div className="pagination-section columns right">
                    {currentPage + 1 < totalPageCount
                        ? <a className="paginate-direction"
                             title={`Weiter zu Seite ${currentPage + 2}`}
                             href="/jobs"
                             onClick={event => paginate(event, (currentPage + 1) * PAGE_SIZE)}>
                            <span className="paginate-direction-text">weiter</span> <img src="../img/forward.svg"
                                                                                         alt="forward"/>
                        </a>
                        : <span>&nbsp;</span>
                    }
                </div>
            </div>
        </div>
    ) : null;
};

export default Pagination;
