import React, { Component } from 'react';
import ContentHeader from "./ContentHeader";
import {setPageMeta} from "../utils/utils";

class Privacy extends Component {

    componentDidMount() {
        setPageMeta({
            title: "Datenschutzerklärung - Jumble",
            description: "Jumble Datenschutzerklärung",
            keywords: "Datenschutz, Datenschutzerklärung, Jumble"
        });
    }

    render() {
        return (
            <div>
                <ContentHeader/>
                <div className="container">
                    <div className="section get-help">
                        <h1>Datenschutzerklärung</h1>
                        <div className="section-description">
                            <p><strong>Datenschutz</strong><br/>Nachfolgend möchten wir Sie über unsere Datenschutzerklärung
                                informieren. Sie finden hier Informationen über die Erhebung und Verwendung persönlicher
                                Daten bei der Nutzung unserer Webseite. Wir beachten dabei das für Deutschland geltende
                                Datenschutzrecht. Sie können diese Erklärung jederzeit auf unserer Webseite abrufen.
                                <br/><br/>
                                Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                                Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff
                                durch Dritte geschützt werden kann.
                                <br/><br/>
                                Die Verwendung der Kontaktdaten unseres Impressums zur gewerblichen Werbung ist ausdrücklich
                                nicht erwünscht, es sei denn wir hatten zuvor unsere schriftliche Einwilligung erteilt oder
                                es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website
                                genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer
                                Daten.
                                <br/><br/>
                                <strong>Personenbezogene Daten</strong>
                                <br/>
                                Sie können unsere Webseite ohne Angabe personenbezogener Daten besuchen. Soweit auf unseren
                                Seiten personenbezogene Daten (wie Name, Anschrift oder E-Mail Adresse) erhoben werden,
                                erfolgt dies, soweit möglich, auf freiwilliger Basis. Diese Daten werden ohne Ihre
                                ausdrückliche Zustimmung nicht an Dritte weitergegeben. Sofern zwischen Ihnen und uns ein
                                Vertragsverhältnis begründet, inhaltlich ausgestaltet oder geändert werden soll oder Sie an
                                uns eine Anfrage stellen, erheben und verwenden wir personenbezogene Daten von Ihnen, soweit
                                dies zu diesen Zwecken erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und nutzen
                                personenbezogene Daten soweit dies erforderlich ist, um Ihnen die Inanspruchnahme des
                                Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche personenbezogenen Daten werden nur
                                solange gespeichert wie dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder
                                Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer- und handelsrechtliche
                                Aufbewahrungsfristen berücksichtigt. Auf Anordnung der zuständigen Stellen dürfen wir im
                                Einzelfall Auskunft über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der
                                Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen Aufgaben der
                                Verfassungsschutzbehörden oder des Militärischen Abschirmdienstes oder zur Durchsetzung der
                                Rechte am geistigen Eigentum erforderlich ist.</p>
                            <p><strong>Auskunftsrecht</strong><br/>Sie haben das jederzeitige Recht, sich unentgeltlich und
                                unverzüglich über die zu Ihrer Person erhobenen Daten zu erkundigen. Sie haben das
                                jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer angegeben persönlichen Daten mit
                                Wirkung für die Zukunft zu widerrufen. Zur Auskunftserteilung wenden Sie sich bitte an den
                                Anbieter unter den Kontaktdaten im Impressum.</p>
                            <p><strong>Cookies</strong><br/>
                                Cookies sind kleine Dateien, die es ermöglichen, auf dem Zugriffsgerät der Nutzer (PC,
                                Smartphone o.ä.) spezifische, auf das Gerät bezogene Informationen zu speichern. Sie dienen
                                zum einem der Benutzerfreundlichkeit von Webseiten und damit den Nutzern (z.B. Speicherung
                                von Logindaten). Zum anderen dienen sie, um die statistische Daten der Webseitennutzung zu
                                erfassen und sie zwecks Verbesserung des Angebotes analysieren zu können. Die Nutzer können
                                auf den Einsatz der Cookies Einfluss nehmen. Die meisten Browser verfügen eine Option mit
                                der das Speichern von Cookies eingeschränkt oder komplett verhindert wird. Allerdings wird
                                darauf hingewiesen, dass die Nutzung und insbesondere der Nutzungskomfort ohne Cookies
                                eingeschränkt werden.<br/>
                                Sie können viele Online-Anzeigen-Cookies von Unternehmen über die US-amerikanische Seite <a rel="nofollow" href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die EU-Seite <a rel="nofollow" href="http://www.youronlinechoices.com/uk/your-ad-choices/ ">http://www.youronlinechoices.com/uk/your-ad-choices/</a> verwalten.
                            </p>
                            <p><strong>Mobile App</strong><br/>
                                Im Folgenden möchten wir Sie über die Nutzung personenbezogener Daten in der Jumble Jobs App
                                aufklären. Die Jumble Jobs App nutzt, im Fall dass der Nutzung durch den Nutzer im Vorfeld
                                zugestimmt
                                wurde, den aktuellen Standort des Gerätes, um Jobsuchen im Umkreis des Nutzers
                                durchzuführen. Dabei wird der aktuelle Standort des Nutzers über GPS ermittelt und mit
                                anderen für die Job-Suche wichtigen Informationen, wie gesetzte Filter, Suchworte, gewählte
                                Sortierung etc. an unseren Server gesendet. Daten zu Ihrem Standort werden dabei nur für die
                                Bearbeitung Ihrer Anfrage genutzt und keinem Dritten zur Verfügung gestellt. Die Übertragung
                                Ihrer Standortdaten erfolgt über eine verschlüsselte Verbindung, die Daten werden nach
                                Beendigung Ihrer Anfrage anonymisiert und zur Verbesserung unseres Services statistisch
                                ausgewertet. Die Speicherdauer dieser statistischen Daten beträgt drei bis sechs Monate.
                                Sie können die Jumble Job App auch ohne Gewährung des Zugriffs auf den aktuellen Standort
                                nutzen, die automatische Lokalisierung steht dann nicht zur Verfügung. Sie können sich
                                jedoch auch manuell verorten, indem Sie in der Suchleiste den angezeigten Ort
                                auswählen und in der daraufhin erscheinenden Maske einen Ort manuell auswählen.
                            </p>
                            <p><strong>Widerruf, Änderungen, Berichtigungen und Aktualisierungen</strong><br/>
                                Der Nutzer hat das Recht, auf Antrag unentgeltlich Auskunft zu erhalten über die
                                personenbezogenen Daten, die über ihn gespeichert wurden. Zusätzlich hat der Nutzer das
                                Recht auf Berichtigung unrichtiger Daten, Sperrung und Löschung seiner personenbezogenen
                                Daten, soweit dem keine gesetzliche Aufbewahrungspflicht entgegensteht.<br/>
                            </p>
                            <p className="external-link">Quelle: <a className="external-link" rel="nofollow" href="http://www.juraforum.de/impressum-generator/">Impressumsgenerator</a> entwickelt mit <a className="external-link" rel="nofollow" href="http://www.rechtsanwaelte-hannover.eu/rechtsgebiete/verkehrsrecht/">Anwalt Verkehrsrecht in Hannover</a> sowie <a className="external-link" rel="nofollow" href="http://rechtsanwalt-schwenke.de/smmr-buch/datenschutz-muster-generator-fuer-webseiten-blogs-und-social-media/">Datenschutz-Muster von Rechtsanwalt Thomas Schwenke - I LAW it</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Privacy;
