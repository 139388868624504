import React, {Component} from 'react';
import qs from 'query-string';

class ResultsHeader extends Component {

    handleSortChange(event) {
        event.preventDefault();
        const sort = event.target.options[event.target.selectedIndex].value;
        this.props.onSortChange(sort);
        this.setState({ sort })
    };

    constructor(props) {
        super(props);
        const { sort } = qs.parse(window.location.search);
        this.state = { sort: sort ? sort : "relevance" };
    }

    render() {
        return (
            <div className="results-header">
                <div className="u-pull-right">
                    <span className="sort-label">Sortieren nach </span>
                    <select id="sort"
                            name="sort"
                            className="select-filter-min"
                            onChange={this.handleSortChange.bind(this)}
                            defaultValue={this.state.sort}>
                        <option value="relevance">Relevanz</option>
                        <option value="newest">Aktualität</option>
                        <option value="distance">Entfernung</option>
                    </select>
                </div>
                <div id="hits">
                    <span>Ihre {this.isRealestate() ? "Immobiliensuche" : "Jobsuche"} ergab <strong>{this.props.hits || 0}</strong></span> Treffer
                </div>
            </div>
        )
    }

    isRealestate() {
        return window.location.pathname.startsWith("/realestate");
    }
}

export default ResultsHeader;
