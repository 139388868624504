import {LOCAL_STORAGE_KEY} from "../config";

const setValue = (value) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
    } catch (e) {
        // do nothing
    }
};

const getValue = () => {
    try {
        const value = localStorage.getItem(LOCAL_STORAGE_KEY);
        return JSON.parse(value);
    } catch (e) {
        // do nothing
    }
    return null;
};

export {
    setValue,
    getValue
}