import React, { Component } from 'react';
import {dateStringToDate, isMobileResolution, timeBetween} from "../utils/utils";

class JobsResultItem extends Component {

    filterByTag(event, tag) {
        if (!isMobileResolution()) {
            event.preventDefault();
            this.props.onFilter(tag);
        }
    }

    relocate(event, l) {
        if (!isMobileResolution()) {
            event.preventDefault();
            this.props.onLocationChange({l});
        }
    }

    render() {
        return (
            <a href={this.props.url} title={this.props.title} rel="nofollow">
                <div className="row result-item">
                    <div className={ this.props.logoUrl ? "result-item-wrapper ten columns" : "result-item-wrapper twelve columns" }>
                        <div className="result-item-details">
                            <div className="row">
                                <div className="twelve columns">
                                    <span className="result-item-title">{this.props.title}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="twelve columns result-item-advertiser">
                                    <div className="result-item-advertiser text">{this.props.advertiser}</div>
                                    <span>&nbsp;-&nbsp;</span>
                                    {this.props.locations.map(location => {
                                        return (
                                            <div key={location} className="result-item-tag location" onClick={(event) => this.relocate(event, location)}>{location}</div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="twelve columns">
                                    <div className="result-item-description">{this.props.description}</div>
                                </div>
                            </div>
                            <div className="row result-item-additional-information">
                                <div className="twelve columns">
                                    { this.props.lastModified && (
                                        <div className="result-item-startdate">{timeBetween(new Date(), dateStringToDate(this.props.lastModified))}</div>
                                    )}
                                    <div className="result-item-tags">
                                        { this.props.tags && this.props.tags.map(tag => {
                                            return (
                                                <div key={tag} className="result-item-tag" onClick={(event) => this.filterByTag(event, tag)}>{tag}</div>
                                            )})
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { this.props.logoUrl && (
                        <div className="two columns">
                            <div className="result-item-image-wrap">
                                    <img className="result-item-image"
                                         src={this.props.logoUrl === "img/ba.svg" ? "../img/ba.svg" : this.props.logoUrl}
                                         title={this.props.advertiser}
                                         alt={this.props.advertiser}/>
                            </div>
                        </div>
                    )}
                </div>
            </a>
        );
    }
}

export default JobsResultItem;
