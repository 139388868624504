import React, { Component } from 'react';
import ContentHeader from "./ContentHeader";

class Home extends Component {
    render() {
        return (
            <div>
                <ContentHeader/>
                <div className="container">
                    <div className="jumbotron main-teaser-background no-padding-top">
                        <div className="container no-padding-top">
                            <h1>404 not found</h1>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
