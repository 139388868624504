import * as qs from "query-string";

const dateStringToDate = (isoDateString) => {
    if (isNumber(isoDateString)) { // TODO remove after go live
        return new Date(isoDateString);
    }
    const dateSplit = isoDateString.split(/[^0-9]/);
    return new Date(Date.UTC(dateSplit[0],dateSplit[1] - 1, dateSplit[2], dateSplit[3], dateSplit[4], dateSplit[5]));
};

const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
};

const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;

const timeBetween = (date1, date2) => {
    var difference_ms = Math.abs(date1 - date2);
    var timeBetween;
    var start = 'vor ';
    if (difference_ms >= ONE_DAY) {
        timeBetween = Math.round(difference_ms/ONE_DAY);
        if (timeBetween > 30) {
            timeBetween = '30+';
        }
        start += timeBetween + ' Tag';
        if (timeBetween !== 1) {
            start += 'en';
        }
    }
    else {
        timeBetween = Math.round(difference_ms/ONE_HOUR);
        if (timeBetween === 0) {
            return 'gerade eben';
        }
        start += timeBetween + ' Stunde';
        if (timeBetween > 1) {
            start += 'n';
        }
    }
    return start;
};

const isHome = () => {
    return window.location.pathname === "/";
};

const isJobs = () => {
    return window.location.pathname === "/jobs";
};

const clean = (obj) => {
    for (let propName in obj) {
        if (propName === "q" || propName === "l") {
            continue;
        }
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj;
};

const isMobileResolution = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 550;
};

const isDesktopResolution = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width >= 1440;
};

const toQueryString = (obj) => {
    return qs.stringify(clean(obj));
};

const updateUrl = (obj, history) => {
    const urlParams = qs.parse(history.location.search);
    const newUrlParams = Object.assign({}, urlParams, obj);
    history.push(history.location.pathname + "?" + toQueryString(newUrlParams));
};

const setPageMeta = (pageMeta) => {
    const { title, description, keywords } = pageMeta;
    setTitle(title);
    setDescription(description);
    setKeywords(keywords);
};

const setTitle = (title) => {
    document.title = title;
};

const setDescription = (description) => {
    const metaDescription = getMetaDescription("description");
    if (metaDescription != null) {
        metaDescription.content = description;
    }
};

const setKeywords = (keywords) => {
    const metaDescription = getMetaDescription("keywords");
    if (metaDescription != null) {
        metaDescription.content = keywords;
    }
};

const getMetaDescription = (name) => {
    const meta = document.getElementsByTagName("meta");
    for (let i = 0; i < meta.length; i++) {
        if (meta[i].name.toLowerCase() === name) {
            return meta[i];
        }
    }
    return null;
};

export {
    timeBetween,
    isHome,
    isJobs,
    toQueryString,
    updateUrl,
    dateStringToDate,
    clean,
    isMobileResolution,
    isDesktopResolution,
    setPageMeta
}