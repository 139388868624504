import React, {Component} from 'react';
import {setPageMeta} from "../../utils/utils";

class Home extends Component {

    componentDidMount() {
        setPageMeta({
            title: "Jobsuchmaschine Jumble - Jobs und Stellenangebote",
            description: "Jobsuche mit Jumble, der Suchmaschine für Stellenangebote. Finden Sie aktuelle Jobs und Stellenangebote in Deutschland und bewerben Sie sich.",
            keywords: "Jumble, Jobsuche, Jobs, Suchmaschine, Stellenangebote, Stellenangebot, Job, Jobbörse, Stellenanzeigen, Jobs suchen, Karriere, Einstellung, Arbeit, Jobs finden, Stellenanzeige, Stellenmarkt, Anstellung, Arbeit, Deutschland, jumble.de"
        });
    }

    render() {
        return (
            <div className="container">
                <div className="home">
                    <div className="main-logo-wrapper">
                        <div className="main-logo">
                            <img className="u-full-width" src="../img/jumble.svg"
                                 alt="Jumble - die Jobsuchmaschine" width="400"/>
                            <h1>API</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
