import React, {Component} from 'react';
import qs from 'query-string';
import {API_BASE_CUNUM, GIS_PATH, JOBS_PATH, PAGE_SIZE, RESULTLIST_KEYWORDS, STATISTICS_TAGS_PATH} from "../config";
import JobsResultItem from "./JobsResultItem";
import {
    isDesktopResolution,
    setPageMeta,
    toQueryString,
    updateUrl
} from "../utils/utils";
import ResultsHeader from "./ResultsHeader";
import FilterList from "./FilterList";
import Pagination from "./Pagination";
import Header from "./Header";
import ExpandCollapse from "./ExpandCollapse";

class JobsResultList extends Component {

    DEFAULT_PARAMS = {
        l: "",
        q: "",
        count: PAGE_SIZE,
        lat: null,
        lng: null
    };

    constructor(props) {
        super(props);
        this.state = {
            searchParams: this.DEFAULT_PARAMS,
            jobs: [],
            hits: null,
            mostTagged: [],
            loading: true,
            filtersOpen: isDesktopResolution()
        };
        this.onSearch = this.onSearch.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    componentDidMount() {
        this.updateState();
    }

    updateState() {

        const urlParams = qs.parse(this.props.history.location.search);
        const searchParams = Object.assign({}, this.DEFAULT_PARAMS, {
            start: urlParams.s,
            lat: this.state.searchParams.lat,
            lng: this.state.searchParams.lng,
            removeDirectOffer: false
        }, urlParams);

        if (!urlParams.l || urlParams.l === "") {
            Object.assign(searchParams, { lat: null, lng: null });
            this.updateResultlist(searchParams);
        } else if (urlParams.l && this.state.searchParams.l !== urlParams.l) {
            fetch(API_BASE_CUNUM + GIS_PATH + "?q=" + urlParams.l)
                .then(results => results.json())
                .then(suggestions => {
                    if (suggestions && suggestions.length > 0) {
                        const coordinates = suggestions[0].location.split(" ");
                        Object.assign(searchParams, {lat: coordinates[1], lng: coordinates[0]});
                        this.updateResultlist(searchParams);
                    }
                })
                .catch(error => console.error(error));
        } else {
            this.updateResultlist(searchParams);
        }
    }

    updateResultlist(searchParams) {

        this.setState({ searchParams, loading: true });
        const queryString = toQueryString(searchParams);

        fetch(API_BASE_CUNUM + JOBS_PATH + "?" + queryString)
            .then(results => results.json())
            .then(results => {
                setPageMeta({
                    title: results.title,
                    description: results.description,
                    keywords: RESULTLIST_KEYWORDS
                });
                this.setState({
                    hits: results.hits,
                    jobs: results.jobs || [],
                    loading: false
                });
            })
            .catch(e => console.error(e));
    }

    render() {
        return (
            <div>
                <Header searchParams={this.state.searchParams}
                        onSearch={this.onSearch}
                        onToggleFilterInput={this.onToggleFilterInput.bind(this)}/>

                <ExpandCollapse isOpen={this.state.filtersOpen} isHalf={false}>
                    <FilterList url={API_BASE_CUNUM + JOBS_PATH + STATISTICS_TAGS_PATH}
                                searchParams={this.state.searchParams}
                                onFilter={this.onFilter}
                                onRemoveFilter={this.onRemoveFilter.bind(this)}/>
                </ExpandCollapse>

                {this.state.loading === true ? this.renderLoader() : this.renderResults()}
            </div>
        );
    }

    renderLoader() {
        return (
            <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"/>
                <div className="sk-cube sk-cube2"/>
                <div className="sk-cube sk-cube3"/>
                <div className="sk-cube sk-cube4"/>
                <div className="sk-cube sk-cube5"/>
                <div className="sk-cube sk-cube6"/>
                <div className="sk-cube sk-cube7"/>
                <div className="sk-cube sk-cube8"/>
                <div className="sk-cube sk-cube9"/>
            </div>
        );
    }

    renderResults() {

        const currentPage = this.state.searchParams.start / PAGE_SIZE;
        const totalPageCount = Math.ceil(this.state.hits / PAGE_SIZE);

        return (
            <div className="container resultlist-content">

                <div className="resultlist">
                    <ResultsHeader hits={this.state.hits} onSortChange={this.onSortChange.bind(this)}/>
                    <div id="results" className="v-spacer">
                        { this.state.jobs.map(job => {
                            return (
                                <JobsResultItem key={ job.id }
                                                title={ job.title }
                                                advertiser={ job.advertiser }
                                                url={ job.url }
                                                logoUrl={ job.logoUrl }
                                                locations={ job.locationNames }
                                                startDate={ job.startDate }
                                                tags={ job.tags }
                                                description={ job.description }
                                                lastModified={ job.lastModified }
                                                onLocationChange={this.onSearch}
                                                onFilter={this.onFilter}
                                />
                            )})
                        }
                    </div>
                </div>

                <Pagination hits={this.state.hits || 0}
                            currentPage={currentPage || 0}
                            totalPageCount={totalPageCount || 0}
                            onPagination={this.onPagination.bind(this)}/>

            </div>
        );
    }

    onToggleFilterInput() {
        this.setState({ filtersOpen: !this.state.filtersOpen });
    }

    onFilter(tag) {
        const urlParams = qs.parse(window.location.search);
        const newTags = urlParams.tags ? urlParams.tags + "," + tag : tag;
        updateUrl({ tags: newTags, s: null }, this.props.history);
        this.updateState();
    }

    onRemoveFilter(tag) {
        const urlParams = qs.parse(window.location.search);
        if (urlParams.tags) {
            const tags = urlParams.tags.split(",");
            const newTags = tags.filter(t => t !== tag).join(",");
            updateUrl({ tags: newTags, s: null }, this.props.history);
            this.updateState();
        }
    }

    onPagination(start) {
        updateUrl({ s: start }, this.props.history);
        this.updateState()
    }

    onSortChange(sort) {
        updateUrl({ sort }, this.props.history);
        this.updateState()
    }

    onSearch(state) {
        const newState = Object.assign({}, state, {s: null});
        updateUrl(newState, this.props.history);
        this.updateState();
    }
}

export default JobsResultList;
