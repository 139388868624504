import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {isHome, isJobs} from "../utils/utils";
import {TYPE_CHANGE_EVENT} from "../config";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "jobs"
        }
    }

    componentDidMount() {
        window.addEventListener(TYPE_CHANGE_EVENT, (e) => {
            this.setState({type: e.detail})
        }, false);
    }

    render() {
        return (
            <div className={isHome() ? "table-block jumble-home-footer" : "table-block jumble-misc-footer"}>
                <div className="container">
                    <div className="footer">
                        {isHome() && (
                            <ul>
                                <li>
                                    <a target="_blank" rel="nofollow noopener noreferrer"
                                       href={this.state.type === "jobs"
                                           ? "https://itunes.apple.com/de/app/jumble-jobs/id1126837760?mt=8"
                                           : "https://itunes.apple.com/de/app/jumble-immo/id1268839298?mt=8"}>
                                        <img style={{verticalAlign: "middle"}} width="135" height="40" src="../img/appstore_badge.svg"
                                             alt={this.state.type === "jobs" ? "Jumble Jobs iOS Appstore Badge" : "Jumble Immo iOS Appstore Badge"}/>
                                    </a>
                                </li>
                            </ul>
                        )}
                        <ul>
                            { isJobs() && (
                                <li>
                                    <a target="_blank" rel="nofollow noopener noreferrer"
                                       href={isJobs()
                                           ? "https://itunes.apple.com/de/app/jumble-jobs/id1126837760?mt=8"
                                           : "https://itunes.apple.com/de/app/jumble-immo/id1268839298?mt=8"}>
                                        <img style={{verticalAlign: "middle"}} width="135" height="40" src="../img/appstore_badge_light.svg"
                                             alt={isJobs() ? "Jumble Jobs iOS Appstore Badge" : "Jumble Immo iOS Appstore Badge"}/>
                                    </a>
                                </li>
                            )}
                            <li className={this.getClassname('/about')}>
                                <Link className="nav-link" to='/about'>Über Jumble</Link>
                            </li>
                            <li className={this.getClassname('/impressum')}>
                                <Link className="nav-link" to='/impressum'>Impressum</Link>
                            </li>
                            <li className={this.getClassname('/privacy')}>
                                <Link className="nav-link" to='/privacy'>Datenschutz</Link>
                            </li>
                            <li className={this.getClassname('/discover')}>
                                <Link className="nav-link" to='/discover'>Entdecke</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    getClassname(route) {
        return route === window.location.pathname ? 'nav-item active' : 'nav-item';
    }
}

export default Footer;
