// urls
const API_BASE_CUNUM = 'https://api.cunum.com/v1';
const STATISTICS_TAGS_PATH = '/statistics/tags/';
const STATISTICS_CITY_PATH = '/statistics/city/';
const STATISTICS_PROFESSION_PATH = '/statistics/profession/';
const STATISTICS_PATH = '/statistics';
const REALESTATE_PATH = '/realestate';
const JOBS_PATH = '/jobs';
const GIS_PATH = '/gis/suggest';

// resultlist + pagination
const PAGE_SIZE = 15;

// cookie
const LOCAL_STORAGE_KEY = "jumble";

// custom event
const TYPE_CHANGE_EVENT = "typeChange";

// seo
const RESULTLIST_KEYWORDS = "Suchergebnisse, Job Suchmachschine, Jobs, Stellenanzeigen, Stellenangebot, Karriere, Einstellung, Beschäftigung, Jobs, jumble.de";

export {
    API_BASE_CUNUM,
    REALESTATE_PATH,
    STATISTICS_CITY_PATH,
    STATISTICS_PROFESSION_PATH,
    STATISTICS_TAGS_PATH,
    STATISTICS_PATH,
    JOBS_PATH,
    PAGE_SIZE,
    GIS_PATH,
    LOCAL_STORAGE_KEY,
    RESULTLIST_KEYWORDS,
    TYPE_CHANGE_EVENT
}