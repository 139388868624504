import React, {Component} from 'react';
import Autosuggest from 'react-autosuggest';
import {API_BASE_CUNUM, GIS_PATH} from "../config";

class LocationAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            suggestions: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ value: this.props.location ? this.props.location : "" });
        }
    }

    renderSuggestion(suggestion) {
        return (<div>{suggestion.name}</div>);
    }

    onSuggestionSelected = (event, selected) => {
        event.preventDefault();
        this.props.onLocationSelected(selected.suggestion);
    };

    getSuggestionValue(suggestion) {
        return suggestion.name;
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        fetch(API_BASE_CUNUM + GIS_PATH + "?q=" + value)
            .then(results => results.json())
            .then(suggestions => {
                this.setState({
                    suggestions: suggestions
                });
            })
            .catch(error => console.error(error));
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: "Stadt oder Gemeinde",
            value,
            onChange: this.onChange,
            name: "l"
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                highlightFirstSuggestion={true}
                onSuggestionSelected={this.onSuggestionSelected}
            />
        );
    }
}

export default LocationAutocomplete;
